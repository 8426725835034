import React from "react";

function Layout({ children }) {
  return (
    <div className="flex flex-col justify-center items-center px-[16px] sm:px-[30px] md:px-[40px] lg:px-[60px]">
      <div className="w-full h-full">{children}</div>
    </div>
  );
}

export default Layout;
