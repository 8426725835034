import { React, lazy, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import NavbarOutlet from "../layouts/NavbarOutlet";

const Home = lazy(() => import("../pages/home/Home"));

function Router() {
  let routes = useRoutes([
    {
      element: <NavbarOutlet />,
      children: [
        {
          element: (
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          ),
          path: "/",
        },
      ],
    },
    {
      element: <Navigate to="/" />,
      path: "*",
    },
  ]);
  return routes;
}

export default Router;
