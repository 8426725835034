import { useRef } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import "./App.css";
import Router from "./routes/Router";
import ScrollToTop from "./utils/ScrollToTop";
import "./assets/styles/styles.css";

function App() {
  const { ref } = useRef(null);
  const options = {
    smooth: true,
    multiplier: 1,
  };
  return (
    <>
      {/* <LocomotiveScrollProvider options={options} containerRef={ref}> */}
      {/* <main data-scroll-container data-scroll-speed="9" ref={ref}> */}
      <ScrollToTop />
      <Router />
      {/* </main> */}
      {/* </LocomotiveScrollProvider> */}
    </>
  );
}

export default App;
