import React from "react";
import { BeatLoader } from "react-spinners";
import Logo from "../assets/icons/logo.svg";

function PageLoading() {
  return (
    <div className="flex flex-col items-center justify-center h-[100vh] pb-[5vh]">
      <img className="h-[150px] mb-[10px]" src={Logo} alt="Turkmen Express" />
      <BeatLoader color={"white"} loading={true} width={"550px"} />
    </div>
  );
}

export default PageLoading;
