import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "./Layout";
import logo from "../assets/icons/logo.svg";
import logoBlue from "../assets/icons/logoBlue.svg";

const Navbar = () => {
  const [navBg, setNavBg] = useState(false);
  const [hamburgerOpen, setHabburgerOpen] = useState(false);
  const navigate = useNavigate();
  const changeNavBg = () => {
    window.scrollY >= 100 ? setNavBg(true) : setNavBg(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNavBg);
    return () => {
      window.removeEventListener("scroll", changeNavBg);
    };
  }, []);

  const handleNavbarNavigate = (href) => {
    navigate(href);
    setHabburgerOpen(false);
  };

  const socials = ["Twitter", "Instagram", "Facebook", "LinkedIn"];

  const items = [
    {
      title: "About",
      href: "#about",
    },
    {
      title: "Services",
      href: "#services",
    },
    {
      title: "Projects",
      href: "#projects",
    },
    {
      title: "Contact",
      href: "#contact",
    },
  ];

  return (
    <>
      <div
        className={`bg-black fixed top-0 z-[50] transition-all duration-700 flex flex-col-reverse justify-center gap-[60px] lg:flex-row items-center pt-[60px] ${
          hamburgerOpen ? "w-[100vw] h-[100vh]" : "w-full h-0"
        }`}
      >
        <div
          className={`hidden  lg:h-[503px] px-[16px] transition-all duration-1000 flex-col-reverse lg:flex-row justify-center gap-[60px] lg:justify-center w-full lg:gap-[218px] ${
            hamburgerOpen && "!flex"
          }`}
        >
          <p className="text-white text-[16px] font-sequel lg:hidden">
            hello@whaleapps.co
          </p>
          <div className="flex flex-col justify-between h-fit lg:h-full">
            <img
              className="hidden lg:inline w-[247px] h-[164px]"
              src={logoBlue}
              alt=""
            />
            <div className="">
              {socials.map((item, index) => (
                <p
                  className="text-white font-[400] text-[20px] cursor-pointer hover:text-stone-400 duration-300"
                  key={index}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
          <div className="flex flex-col">
            {items.map((item, index) => (
              <a
                href={item.href}
                className="text-white font-[500] text-[42px] lg:text-[96px] font-sharp cursor-pointer hover:text-stone-400 duration-300 hover:scale-[0.99]"
                key={index}
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`fixed z-50 w-full transition-all duration-700 px-[16px] lg:px-[60px] ${
          navBg ? "bg-black" : "bg-transparent"
        }`}
      >
        {/* Desktop Size */}
        <div className={`flex items-center h-[86px] justify-between w-full `}>
          <img
            className={`${
              hamburgerOpen && "opacity-0 duration-0"
            } cursor-pointer transition-all duration-1000 w-[60px] lg:w-[84px] h-[40px] lg:h-[54px] opacity-1 
            `}
            src={logo}
            onClick={() => navigate("/")}
            alt="Whaleapps"
          />
          <div
            className="flex flex-col gap-[10px] cursor-pointer "
            onClick={() => setHabburgerOpen(!hamburgerOpen)}
          >
            <span
              className={`relative transition-all duration-300 h-[2px] bottom-[0] rounded-[10px] w-[24px] bg-white ${
                hamburgerOpen && "firstLineToggled"
              }`}
            ></span>
            <span
              className={`relative transition-all duration-300 h-[2px] rounded-[10px] bottom-[0] w-[24px] bg-white ${
                hamburgerOpen && "secondLineToggled"
              }`}
            ></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
